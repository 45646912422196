import { RawDraftContentState } from "draft-js";
import { AnchorHTMLAttributes, ComponentType, ReactNode } from "react";
import { Store } from "redux";
import {
  ThunkAction as _ThunkAction,
  ThunkDispatch as _ThunkDispatch,
} from "redux-thunk";
import isURL from "validator/lib/isURL.js";
import { spriteData } from "../../dist/svg-sprite/icons-data.js";
import { spriteData as separatorSpriteData } from "../../dist/svg-sprite/separator-icons-data.js";
import {
  APILogo,
  APIPartner,
  APISite,
  ColorSchemesAPI,
  IssuesChecker,
  LogoBackground,
  LogoTranslation,
  PartnerTranslation,
  Language as ServerLanguage,
  SiteLanguage,
} from "../../server/types/index.js";
import { AllActions } from "../actions/index.js";
import { minWidthBreakpoints } from "../components/ContainerQueries.js";
import {
  weatherDistrictIds,
  weatherIconStyles,
} from "../components/ModuleSettings/WeatherModuleSettings.js";
import { legalForms, specialThemes } from "../utils/data.js";
import {
  fontStyles,
  fontSubsets,
  fontVariants,
  fontWeights,
} from "../utils/fonts.js";
import { usercentricsDataProcessingServices } from "../utils/usercentrics.js";
import {
  actionLinkTypes,
  enquiryBookingLinkTypes,
  taskStates,
  taskTypes,
  threeSizes,
} from "../utils/utils.js";

type Nullable<T> = { [P in keyof T]: T[P] | null };

export type Process = {
  env: {
    NODE_ENV: "development" | "production";
    CMS_ORIGIN?: string;
    GOOGLE_MAPS_API_KEY: string;
  };
};

export type ThunkAction<R> = _ThunkAction<R, StoreState, void, AllActions>;
export type ThunkDispatch = _ThunkDispatch<StoreState, void, AllActions>;

export interface PreloadedState {
  pageId: string;
  languageId: Language;
  // TODO: preloaded store state is not StoreState
  state: StoreState;
}

export interface WidgetInstance {
  render: () => void;
  unmount: () => boolean;
}

export type SeparatorIconName = keyof typeof separatorSpriteData.viewBoxes;

export interface SeparatorIconType {
  title: string;
  id: SeparatorIconName;
  author: string;
  url: string;
  accommodationId?: number;
}

export type IconName = keyof typeof spriteData.viewBoxes;

interface UsercentricsService {
  id: string;
  name: string;
  consent: {
    status: boolean;
  };
}

interface UsercentricsSettings {
  ui: {
    customization: {
      color: {
        primary: string;
      };
    };
  };
}

export interface UsercentricsUI {
  getServicesBaseInfo: () => UsercentricsService[];
  showSecondLayer: () => Promise<void>;
  showFirstLayer: () => Promise<void>;
  getSettings: () => UsercentricsSettings;
  acceptService: (id: string) => Promise<void>;
  isInitialized: () => boolean;
}

export type UsercentricsServiceName =
  (typeof usercentricsDataProcessingServices)[number];

type UsercentricsEventData = { [key in UsercentricsServiceName]?: boolean };

export interface UsercentricsEvent extends Event {
  detail: {
    event: "consent_status";
  } & UsercentricsEventData;
}

export interface WindowState extends Window {
  google: typeof google | undefined;
  BookingSüdtirol: {
    Widgets: {
      Booking: (
        el: HTMLElement,
        settings: BookingWidgetSettings
      ) => WidgetInstance;
      Enquiry: (
        selector: HTMLElement | string,
        settings: EnquiryWidgetSettings
      ) => WidgetInstance;
      QuickEnquiry: (
        selector: HTMLElement | string,
        settings: QuickEnquiryWidgetSettings
      ) => WidgetInstance;
      Specials: (
        selector: HTMLElement | string,
        settings: OfferingsWidgetSettings
      ) => WidgetInstance;
      Rooms: (
        selector: HTMLElement | string,
        settings: OfferingsWidgetSettings
      ) => WidgetInstance;
      Prices: (
        selector: HTMLElement | string,
        settings: PricesWidgetSettings
      ) => WidgetInstance;
      Weather: (
        selector: HTMLElement | string,
        settings: WeatherWidgetSettings
      ) => WidgetInstance;
      Portal: (
        selector: HTMLElement | string,
        settings: PortalWidgetSettings
      ) => WidgetInstance;
    };
  };
  BookingSüdtirolTrackingConsent?: boolean;
  YT?: typeof YT;
  loadframe?: () => void; // EasiPay
  dataLayer?: {
    push: (options: { event: string }) => void;
  };
  UC_UI?: UsercentricsUI;
}

export type ModalType = "message" | "confirm";
export type ModalStatus = "confirmed" | "refuted";

export interface GeneratorStoreState {
  sites: SiteState;
  accommodation: AccommodationState;
  pages: PageState;
  modules: Modules;
  colorSchemes: ColorSchemesAPI;
  fonts: Fonts;
  mediaLibrary: MediaLibrary;
  partners: PartnersState;
  logos: LogosState;

  // richEditor is only included to not throw an error in RichEditor mapStateToProps
  // TODO: find a better solution for this
  richEditor: RichEditorState;
  legalNav: LegalNavState;
  loadStates: LoadStates;
}

export interface StoreState extends GeneratorStoreState {
  alerts: Alert[];
  login: LoginState;
  linkSelect: LinkSelect;
  revisions: StoreRevisions;
  issues: IssuesState;
  seo: Seo;
  user: User;
  rooms: Room[];
  specials: Special[];
  prices: PricesState;
  publish: Publish;
  languages: Language[];
}

export type Board = 0 | 1 | 2 | 3 | 4 | 5;

export type BoardPrices = { [T in Board]: number };

interface TimePeriod {
  start: string;
  end: string;
}

interface PriceOffer {
  label: string;
  offerId: number;
}

export interface PriceOffers {
  [key: string]: PriceOffer;
}

export type Price = TimePeriod &
  PriceOffer & {
    roomId: number;
    boards: BoardPrices;
  };

interface PricesById {
  [id: string]: Price;
}

interface Prices {
  allItems: string[];
  offers: PriceOffers;
  byId: PricesById;
  boards: Board[];
}

export interface PricesState {
  offers: PriceOffers;
  boards: Board[];
  byId: {
    [moduleId: string]: Prices;
  };
}

export interface Special {
  id: number;
  title: string;
  themeIds: SpecialThemeId[];
}

export interface LegalNavState {
  id: LegalNavId | undefined;
}

export interface PartnersState {
  [id: string]: Partner;
}

export type Partner = APIPartner<string[]>;

export interface LogosState {
  [id: string]: Logo;
}

export interface Logo extends APILogo {
  partnerId: string;
}

export interface TranslatedPartnerLogo {
  id: string;
  translation: LogoTranslation;
  background: LogoBackground;
  partner: TranslatedPartner;
}

export interface TranslatedPartner {
  id: string;
  translation: PartnerTranslation;
  logos: TranslatedPartnerLogo[];
}

export interface SiteState {
  byId: SitesById;
  allItems: string[];
  noMoreItems: boolean;
  activeSiteId: string | undefined;
}

export interface SitesById {
  [id: string]: APISite;
}

export type RichEditorState = {
  [moduleId in string]?: boolean;
};

export type TaskStatus = (typeof taskStates)[number];

export type TaskType = (typeof taskTypes)[number];

export type PostPublishStatus = "success" | "criticalIssues";

export interface Publish {
  status: TaskStatus;
}

export interface IssueItems {
  allIds: string[];
  byType: { [type in IssueType]?: string[] };
  byId: { [id: string]: IssueItemType };
}

export interface IssuesState {
  issues: IssueItems;
  checker: IssuesChecker;
}

export interface LinkSelect {
  linkData: LinkData;
  id: string | undefined;
  confirmed: boolean;
}

export interface LoginState {
  isSubmitting: boolean;
  error: ServerError | undefined;
}

export interface AuthenticatedUser {
  siteId: string;
  token: string;
  email: string;
  isAdmin: boolean;
}

export type User = Partial<AuthenticatedUser>;

export interface LoginForm {
  email: string;
  password: string;
}

export interface ServerError {
  statusCode: number;
  error: string;
  message: string;
}

export type LoadStatus = "unloaded" | "loading" | "loaded" | "error";

export interface ColorSchemes {
  [key: string]: ColorScheme;
}

export interface ColorScheme {
  main: {
    background: string;
    text: string;
    title: string;
    separator: string;
  };
  primary: {
    background: string;
    text: string;
  };
  secondary: {
    background: string;
    text: string;
  };
}

export type ColorSchemeCategory = keyof ColorScheme;

export type FontUsage = "main" | "title";

export type FontsByUsage = { [usage in FontUsage]: string[] };

export type FontVariant = (typeof fontVariants)[number];
export type FontWeight = (typeof fontWeights)[number];
export type FontStyle = (typeof fontStyles)[number];
export type FontSubset = (typeof fontSubsets)[number];

export interface Font {
  family: string;
  usage: FontUsage;
  scale: number;
  forQuotes: boolean;
  variants: {
    weight: FontWeight;
    style: FontStyle;
    subset: FontSubset;
    url: string;
  }[];
}

export type FontsByFamily = { [family in string]?: Font };

export interface Fonts {
  allFamilies: string[];
  byFamily: FontsByFamily;
}

export type ContainerQueries = {
  [K in keyof typeof minWidthBreakpoints]: boolean;
};

export type PicturesByGroupIdLoadStates = {
  [K in number]?: LoadStatus;
};

export interface LoadStates {
  accommodation: { [K in Language]?: LoadStatus };
  colorSchemes: LoadStatus;
  partners: LoadStatus;
  mediaLibrary: {
    pictures: LoadStatus;
    groups: LoadStatus;
    picturesByGroupId: PicturesByGroupIdLoadStates;
  };
  pages: LoadStatus;
  rooms: LoadStatus;
  prices: LoadStatus;
  site: LoadStatus;
  specials: LoadStatus;
  revisions: LoadStatus;
  scripts: { [K in string]?: LoadStatus };
  actionModules: LoadStatus;
  siteModules: LoadStatus;
  fonts: LoadStatus;
  issues: {
    checker: LoadStatus;
    issues: LoadStatus;
  };
}

export interface PageState {
  byId: PagesById;
  byParentId: PagesByParentId;
  byAlias: PagesByAlias;
  systemPages: string[];
}

export interface PagesByParentId {
  ["null"]: string[];
  [key: string]: string[];
}

export interface MainMenuItem {
  id: string;
  href: string;
  target: string | undefined;
  title: string;
  isFolder: boolean;
}

export interface PagesById {
  [pageId: string]: Page;
}

export interface PagesByAlias {
  [pageAlias: string]: string;
}

export interface PagesByChildId {
  [childId: string]: string;
}

interface RawPage {
  id: string;
  uuid: string;
  siteId: string;
  parentId: string | null;
  isEnabled: boolean;
  alias: string | null;
  isExpanded: boolean;
  isSystemPage: boolean;
  inheritHeaderImages: boolean;
  popUpModuleId: string | null;
  modules: Module[];
}

export interface Page extends RawPage {
  translations: { [K in Language]?: PageTranslation };
}

export interface TranslatedPage extends RawPage {
  translation: PageTranslation;
  languages: Language[];
}

export type PageRequest = Partial<{
  parentId: string | null;
  isExpanded: boolean;
  isEnabled: boolean;
  inheritHeaderImages: boolean;
  popUpModuleId: string | null;
  translations: { [K in Language]?: PageTranslationRequest };
}>;

export interface PagePostRequest extends PageRequest {
  translation: PageTranslationPostRequest;
}

export type PageTranslationType = "page" | "redirect" | "folder";

export type PageTranslationRequest = Partial<{
  title: string;
  documentTitle: string | null;
  description: string | null;
  isVisible: boolean;
  slug: string;
  link: LinkData | null;
}>;

export interface PageTranslationPostRequest extends PageTranslationRequest {
  title: string;
  languageId: Language;
}

export interface PageTranslation extends PageTranslationPostRequest {
  slug: string;
  documentTitle: string | null;
  description: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PageLimits {
  enabled: {
    count: number;
    limitReached: boolean;
  };
  all: {
    count: number;
    limitReached: boolean;
  };
}

export interface Modules {
  byId: ModulesById;
  byParentId: ModulesByParentId;
  byPageId: ModulesByPageId;
  bySiteModuleType: ModulesBySiteModuleType;
  actionModules: ActionModules;
}

export type EnquiryBookingLinkType = (typeof enquiryBookingLinkTypes)[number];

export type ActionLinkType = (typeof actionLinkTypes)[number];

export interface ActionLink {
  href: string;
  label: string;
}

export type ActionLinks = { [type in ActionLinkType]: ActionLink | undefined };

export interface ModulesById {
  [moduleId: string]: Module;
}

export type ModulesByParentId = {
  [pageId: string]: {
    [parentId: string]: { [K in ModuleType]?: string[] };
  };
};

export interface ModulesByPageId {
  [pageId: string]: string[];
}

export type ModulesBySiteModuleType = { [K in ModuleType]?: string[] };

export type ActionModule = {
  id: string;
  type: ModuleType;
  pageId: string;
};

export type ActionModules = { [K in Language]?: ActionModule[] };

// TODO: use ServerLanguage directly
export type Language = ServerLanguage;

interface RawModule {
  id: string;
  siteId: string;
  pageId: string | null;
  colorSchemeId: string | null;
  parentId: string | null;
  type: ModuleType;
}

export type ChangeModuleSet = Pick<RawModule, "colorSchemeId">;

export interface ItemWithTranslations {
  translations: { [K in Language]?: unknown };
}

export interface ModuleTranslation<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> {
  settings: Settings["language"];
}

export type ModuleTranslations<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> = { [K in Language]?: ModuleTranslation<Settings> };

export interface Module<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> extends RawModule {
  translations: ModuleTranslations<Settings>;
  settings: Settings["global"];
}

export interface TranslatedModule<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> extends RawModule {
  translation: {
    languageId: Language;
    exists: boolean;
    settings: Settings["language"];
  };
  settings: Settings["global"];
  languages: Language[];
}

export interface DefaultModuleSettings {
  HeaderModule: HeaderModuleSettings;
  FooterModule: FooterModuleSettings;
  ButtonModule: ButtonModuleSettings;
  TextModule: TextModuleSettings;
  ImagesModule: ImagesModuleSettings;
  ImageModule: ImageModuleSettings;
  OverlayModule: OverlayModuleSettings;
  ImageGalleryModule: ImageGalleryModuleSettings;
  RoomsModule: OfferingsModuleSettings;
  MapModule: MapModuleSettings;
  SpecialsModule: OfferingsModuleSettings;
  BookingModule: BookingModuleSettings;
  PortalModule: PortalModuleSettings;
  EnquiryModule: EnquiryModuleSettings;
  QuickEnquiryModule: QuickEnquiryModuleSettings;
  HighlightModule: HighlightModuleSettings;
  HighlightsModule: HighlightsModuleSettings;
  ImprintModule: ImprintModuleSettings;
  PrivacyModule: PrivacyModuleSettings;
  TermsModule: TermsModuleSettings;
  PriceModule: PriceModuleSettings;
  VideoModule: VideoModuleSettings;
  NotFoundTextModule: NotFoundTextModuleSettings;
  EasiPayModule: EasiPayModuleSettings;
  EasiFastCheckInModule: EasiFastCheckInModuleSettings;
  HTMLModule: HTMLModuleSettings;
  SeparatorModule: SeparatorModuleSettings;
  MTSToursModule: MTSToursModuleSettings;
  WeatherWebcamModule: WeatherWebcamModuleSettings;
  WeatherModule: WeatherModuleSettings;
  WebcamModule: WebcamModuleSettings;
  GastroPoolInsuranceModule: GastroPoolInsuranceModuleSettings;
  PopUpModule: PopUpModuleSettings;
  Google360VirtualTourModule: Google360VirtualTourModuleSettings;
  EmbeddedGoogleMapsModule: EmbeddedGoogleMapsModuleSettings;
  NewsletterModule: NewsletterModuleSettings;
  PeerTvModule: PeerTvModuleSettings;
  ACSmartVoucherModule: ACSmartVoucherModuleSettings;
  QuestionsAndAnswersModule: QuestionsAndAnswersModuleSettings;
  QuestionAndAnswerModule: QuestionAndAnswerModuleSettings;
  GuestnetWidgetModule: GuestnetWidgetModuleSettings;
  HogastGastropoolPayModule: HogastGastropoolPayModuleSettings;
  HogastGastropoolSmartPayModule: HogastGastropoolSmartPayModuleSettings;
  QuoteModule: QuoteModuleSettings;
  AccommodationFeaturesModule: AccommodationFeaturesModuleSettings;
  ArriveInSouthTyrolModule: ArriveInSouthTyrolModuleSettings;
  GetavoModule: GetavoModuleSettings;
}

export type ModuleType = keyof DefaultModuleSettings;

export type SystemPageModuleType = Extract<
  ModuleType,
  "ImprintModule" | "PrivacyModule" | "TermsModule"
>;

export type ModuleTypeMapping = { [moduleType in ModuleType]: string };

export type ModuleSettingsTypes = DefaultModuleSettings[ModuleType];

export interface FormModuleSettings<
  T extends ModuleSettingsTypes = ModuleSettingsTypes
> {
  translatedModule: TranslatedModule<T>;
  pageId: string;
  languageId: Language;
}

export type PartialModuleSettings<Settings extends ModuleSettingsTypes> = {
  global?: Partial<Settings["global"]>;
  language?: Partial<Settings["language"]>;
};

export interface BaseModuleProps<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> {
  pageId: string;
  translatedModule: TranslatedModule<Settings>;
  isPreview: boolean;
  queries: ContainerQueries;
  isActive: boolean;
  activeModuleId: string | undefined;
  isFirstOnPage: boolean;
}

export type ThreeSizes = (typeof threeSizes)[number];
export type VerticalAlign = "top" | "center" | "bottom";
export type TextStyle = "background" | "plain";

export type HeaderMenuVariant = "top-bottom-bars" | "bottom-bar";

export type TopHeaderVariant =
  | "hamburger-nav"
  | "below-header-nav"
  | "in-header-nav";

export type HeaderLogoBackground = "transparent" | "color-scheme-background";

export type HeaderImageOverlayGradient = "light" | "dark";

export interface QuickLinksSettings {
  vouchers: LinkData;
  specials: LinkData;
}

export interface HeaderModuleSettings {
  global: {
    layoutVariant: HeaderLayoutVariant;
    showBreadcrumb: boolean;
    sticky: boolean;
    menuVariant: HeaderMenuVariant;
    topHeaderVariant: TopHeaderVariant;
    showMenuSeparators: boolean;
    showSouthTyrolLogo: boolean;
    logoId: string | undefined;
    logoBackground: HeaderLogoBackground;
    imageOverlayGradient: HeaderImageOverlayGradient;
    whatsAppNumber: string | undefined;
    logoSize: ThreeSizes;
  };
  language: {
    activeActionModuleIds: ActiveActionModuleIds;
    links: QuickLinksSettings;
  };
}

export type ActiveActionModuleIds = { [K in EnquiryBookingLinkType]?: string };

export type HeaderLayoutVariant =
  | "all-in-header"
  | "split-menu"
  | "full-overlay-nav"
  | "left-overlay-nav"
  | "fixed-sidebar";

export type TextAlign = "left" | "center" | "right" | "justify";

export interface ButtonModuleSettings {
  global: {
    layout: ButtonLayout;
    corner: "square" | "rounded" | "round";
    type: ButtonType;
  };
  language: {
    title: string;
    linkData: LinkData;
  };
}

export type TextWidth = "narrow" | "wide" | "container-width";
export type TextColumns = 1 | 2;
export type TextBoxAlign = "left" | "center" | "right";

export interface TextModuleSettings {
  global: {
    mediaAspectRatio: AspectRatio;
    mediaAlign: MediaAlign;
    textAlign: TextAligns;
    width: TextWidth;
    columns: TextColumns;
    boxAlign: TextBoxAlign;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
  };
}

export type PopUpImageAlign = "top" | "bottom" | "left";
export type PopUpBoxAlign =
  | "left-top"
  | "left-middle"
  | "left-bottom"
  | "center";

export interface PopUpModuleSettings {
  global: {
    mediaAspectRatio: AspectRatio;
    textAlign: TextAligns;
    imageAlign: PopUpImageAlign;
    boxAlign: PopUpBoxAlign;
    darkBackground: boolean;
    onlyShowWhenUserInactive: boolean;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
  };
}

export interface TitleAndSubtitleTextAlign {
  title: TextAlign;
  subtitle: TextAlign;
}

export interface TextAligns {
  title: TextAlign;
  subtitle: TextAlign;
  description: TextAlign;
  buttons: TextAlign;
}

export type ButtonType = "primary" | "secondary";
export type ButtonLayout = "hollow" | "flat";

export type MediaAlign = "left" | "right";

export type ImagesModuleType = "separator" | "slider";
export type SliderEffect = "slide" | "fade";

export type ImagesModuleGap = ThreeSizes | "none";

export interface ImagesModuleSettings {
  global: {
    mediaAspectRatio: AspectRatio;
    imagesType: ImagesModuleType;
    columnsCount: number;
    width: number;
    gap: ImagesModuleGap;
    sliderEffect: SliderEffect;
    autoPlay: boolean;
    autoPlayDelay: number;
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

// Values are relative to the image dimensions, ranging from 0 to 1.
export interface ImageCropParams {
  x: number;
  y: number;
  width: number;
  height: number;
}

// Values are relative to the crop width/height, ranging from 0 to 1.
export interface Point {
  x: number;
  y: number;
}

export interface ImageDetail {
  crop: ImageCropParams;
  importantPoint: Point;
}

export interface ImageModuleSettings {
  global: { pictureId: string; crop: ImageCropParams; importantPoint: Point };
  language: {};
}

interface OverlaySettingsGlobal {
  fontSize: ThreeSizes;
  horizontalAlign: TextAlign;
  textStyle: TextStyle;
  verticalAlign: VerticalAlign;
}

interface OverlaySettingsLanguage {
  title: string;
  subtitle: string;
}

export interface OverlayModuleSettings {
  global: OverlaySettingsGlobal;
  language: OverlaySettingsLanguage;
}

export interface ImageGalleryModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    mediaAspectRatio: AspectRatio;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface MapModuleSettings {
  global: {
    zoom: number;
  };
  language: {};
}

export interface OfferingsModuleSettings {
  global: Pick<
    OfferingsWidgetSettings,
    "displayType" | "layout" | "maxColumns" | "themeIds"
  > & {
    textAlign: TitleAndSubtitleTextAlign;
    pinnedItems: number[];
    maxItems: number | undefined;
    bookingLayout: BookingWidgetLayout;
    enquiryLayout: EnquiryWidgetLayout;
    mediaAspectRatio: AspectRatio;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface HighlightModuleSettings {
  global: {
    textAlign: TextAligns;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
  };
}

export interface QuestionAndAnswerModuleSettings {
  global: {
    textAlign: TextAligns;
  };
  language: {
    title: string;
    description: RichContentCombined;
    // Populated by the server/generator
    plainDescription: string | undefined;
  };
}

export interface GuestnetWidgetModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    baseUrl: string;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface HogastGastropoolPayModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    key: string;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export type SmartPayProvider = "hogast" | "gastropool";
export interface SmartPayOptions {
  deposit: boolean;
  travelInsurance: boolean;
}

export interface HogastGastropoolSmartPayModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    key: string;
    provider: SmartPayProvider;
    options: SmartPayOptions;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export type QuoteLayout = "layout-1" | "layout-2" | "layout-3";

export interface QuoteModuleSettings {
  global: {
    mediaAlign: MediaAlign;
    layout: QuoteLayout;
    textAlign: TextAligns;
    fontFamily: string | null;
    fontSize: ThreeSizes;
  };
  language: {
    quote: string;
    author: string;
    title: string;
    subtitle: string;
  };
}

export interface AccommodationFeaturesModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    collapsedLinesCount: number | undefined;
    maxColumns: number;
    pinnedItems: number[];
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface ArriveInSouthTyrolModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface GetavoModuleSettings {
  global: {
    code: string;
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface QuickEnquiryModuleSettings {
  global: {
    layout: QuickEnquiryWidgetLayout;
    fieldSettings: QuickEnquiryWidgetFieldSettings;
    badgeStyle: QuickEnquiryBadgeStyle;
  };
  language: {};
}

export type HighlightsLayout =
  | "layout-1"
  | "layout-2"
  | "layout-3"
  | "layout-4"
  | "layout-5"
  | "layout-6"
  | "layout-7"
  | "layout-8"
  | "layout-9";

export interface PriceModuleSettings {
  global: {
    grouping: PricesWidgetGrouping;
    offerIds: number[];
    roomIds: number[];
    boardIds: Board[];
    textAlign: TextAligns;
    layout: PricesWidgetLayout;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
  };
}

export type VideoSize = "small" | "big" | "fullWidth";

export interface VideoModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    videoUrl: string;
    controls: boolean;
    showInfo: boolean;
    autoPlay: boolean;
    startAt: string;
    size: VideoSize;
    mute: boolean;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface EasiPayModuleSettings {
  global: {
    authId: string;
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: { title: string; subtitle: string };
}

export interface EasiFastCheckInModuleSettings {
  global: {
    url: string;
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: { title: string; subtitle: string };
}

export interface MTSToursModuleSettings {
  global: {
    user: string;
    archive: string;
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: { title: string; subtitle: string };
}

export type WeatherWebcamLayoutVariant = "vertical" | "horizontal";

export interface WeatherWebcamModuleSettings {
  global: {
    layoutVariant: WeatherWebcamLayoutVariant;
  };
  language: {};
}

export interface WeatherModuleSettings {
  global: {
    districtId: APIWeatherDistrictId;
    iconStyle: APIWeatherIconStyle;
    textAlign: TextAligns;
  };
  language: { title: string; subtitle: string };
}

export type WebcamLayoutVariant = "image-first" | "image-last";

// TODO: maybe rename big to large
export type WebcamSize = "small" | "big";

export interface WebcamModuleSettings {
  global: {
    url: string;
    textAlign: TextAligns;
    size: WebcamSize;
    layoutVariant: WebcamLayoutVariant;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
    imageCaption: string;
  };
}

export interface GastroPoolInsuranceModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: { title: string; subtitle: string; key: string };
}

export interface Google360VirtualTourModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    url: string | undefined;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface EmbeddedGoogleMapsModuleSettings {
  global: {};
  language: {
    url: string | undefined;
  };
}

export type BasicFieldSetting = "disabled" | "optional";
export type FieldSetting = BasicFieldSetting | "required";

export interface NewsletterModuleFieldSettings {
  gender: FieldSetting;
  firstName: FieldSetting;
  lastName: FieldSetting;
  country: FieldSetting;
}

export type NewsletterModuleLayout =
  | "layout-1"
  | "layout-2"
  | "layout-3"
  | "layout-4";

export interface NewsletterModuleSettings {
  global: {
    textAlign: TextAligns;
    fieldSettings: NewsletterModuleFieldSettings;
    layout: NewsletterModuleLayout;
  };
  language: {
    title: string;
    subtitle: string;
    description: RichContentCombined;
  };
}

export interface PeerTvModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    size: VideoSize;
  };
  language: {
    title: string;
    subtitle: string;
    scriptUrl: string;
    caption: string;
    websiteUrl: string;
  };
}

export interface ACSmartVoucherModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    cssUrl: string;
    kID: string;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface HTMLModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: { title: string; subtitle: string; html: string };
}

type ContainerOrFullWidth = "container-width" | "full-width";
export type SeparatorWidth = "narrow" | ContainerOrFullWidth;

export type LineStyle = "none" | "straight" | "wavy";
export type LineWeight = "light" | "strong";

export interface SeparatorModuleSettings {
  global: {
    width: SeparatorWidth;
    height: ThreeSizes;
    icon: SeparatorIconName | null;
    iconCount: number;
    iconSize: ThreeSizes;
    lineStyle: LineStyle;
    lineWeight: LineWeight;
  };
  language: {};
}

export interface HighlightsModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    layout: HighlightsLayout;
    displayType: HighlightsDisplayType;
    mediaAspectRatio: AspectRatio;
    maxColumnsCount: number;
    collapsedLinesCount: number | undefined;
    transparentSliderArrowBackground: boolean;
    mediaAlign: MediaAlign;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface QuestionsAndAnswersModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export type PartnerLogoSize = "big" | "small";

export type LogoCategories = [top: string[], bottom: string[]];

export interface FooterModuleSettings {
  global: {
    layoutVariant: FooterLayoutVariant;
    logoSize: PartnerLogoSize;
    showTrustYou: boolean;
    logoCategories: LogoCategories;
    textAlign: TextAligns;
  };
  language: {
    title: string;
    description: RichContentCombined;
  };
}

export interface EnquiryModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    fieldSettings: EnquiryWidgetFieldSettings;
    layout: EnquiryWidgetLayout;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export type BookingWidgetLayout = "one_pager" | "four_steps" | "compact";

export type EnquiryWidgetLayout = "layout_1" | "layout_2" | "layout_3";

export type DefaultOfferList = "rooms" | "specials";

export type BookingWidgetLicense = "standard" | "extended";

export interface BookingModuleSettings {
  global: {
    license: BookingWidgetLicense;
    layout: BookingWidgetLayout;
    defaultOfferList: DefaultOfferList;
    textAlign: TitleAndSubtitleTextAlign;
    fieldSettings: BookingWidgetFieldSettings;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

export interface PortalModuleSettings {
  global: {
    textAlign: TitleAndSubtitleTextAlign;
    id: string;
    showSearch: boolean;
  };
  language: {
    title: string;
    subtitle: string;
  };
}

interface NotFoundTextModuleSettings {
  global: {};
  language: {};
}

type RichContentCombined = RawDraftContentState | "string" | undefined;

export interface ModuleWithRichTextSettingsCombined {
  global: {
    textAlign: TextAligns;
  };
  language: {
    title: string;
    description: RichContentCombined;
  };
}

export interface ModuleWithRichTextSettingsDraftOnly {
  global: {
    textAlign: TextAligns;
  };
  language: {
    title: string;
    description: RawDraftContentState | undefined;
  };
}

export interface ImprintModuleSettings {
  global: {
    legalForm: LegalForm | undefined;
    pecAddress: string;
    reaNumber: string;
    taxCode: string;
    paidUpShareCapital: number | undefined;
  };
  language: {
    legalAddress: string;
  };
}

export interface PrivacyModuleSettings {
  global: {
    textAlign: TextAligns;
  };
  language: {
    title: string;
    description: RichContentCombined;
  };
}

export interface TermsModuleSettings {
  global: {};
  language: {};
}

export type FooterLayoutVariant = "big" | "compact";

export type LegalForm = (typeof legalForms)[number];

export interface SiteForm {
  vatId: string | null;
  legalRepresentative: string | null;
  languages: SiteLanguage[];
  googleTagManagerId: string | null;
  googleMapsApiKey: string | null;
}

export type AccommodationState = { [K in Language]?: Accommodation };

export interface Accommodation {
  name: string;
  cin: string[];
  email: string;
  phone: string | null;
  fax: string | null;
  latitude: number;
  longitude: number;
  street: string;
  zip: string;
  city: string;
  trustYou: TrustYou | null;
  boards: Board[];
  stars: number;
  priceRange: string | null;
  checkIn: CheckInOut;
  checkOut: CheckInOut;
  featureMap: number;
  features: Feature[];
  pictures: Picture[];
  gallery: Picture[];
}

interface CheckInOut {
  from: string | null;
  to: string | null;
}

export interface TrustYou {
  id: string;
  value: number;
  count: number;
  date: string;
  stars: ("star" | "star-half" | "star-empty")[];
}

export interface PicturesByGroupId {
  [groupId: number]: string[];
}

export interface MediaLibrary {
  pictures: Pictures;
  logoIds: string[];
  groups: PictureGroup[];
  picturesByGroupId: PicturesByGroupId;
}

export interface Pictures {
  [id: string]: Picture;
}

export type PictureCategory = "logo" | "pictures" | "placeholder";

export interface Picture {
  id: string;
  url: string;
  title: string | null;
  category: PictureCategory;
  copyright: string;
  width: number;
  height: number;
}

export type PartnerLinks = {
  [K in Language]?: {
    hostname: string;
    url: string;
  };
};

export interface Room {
  id: number;
  title: string;
}

export type HighlightsDisplayType = "list" | "slider";

interface Feature {
  id: number;
  title: string;
}

export type Alert = {
  id: string;
  type: "error" | "success" | "message" | "warning";
  text: string;
};

export interface ControlItem {
  id: number | string;
  title: string;
  translateFrom?: Language;
  preview?: ReactNode;
}

const aspectRatios = <const>[
  4, // 4:1
  2.6667, // 8:3
  1.7778, // 16:9
  1.5, // 3:2
  1.3333, // 4:3
  1, // 1:1
  0.75, // 3:4
  0.6667, // 2:3
  0.5625, // 9:16
  0.375, // 3:8
];

export type AspectRatio = (typeof aspectRatios)[number];

// The aspect ratios are reversed here: height/width
// TODO: Remove this as soon as https://github.com/HGV/widgets/issues/11
// is resolved.
export type OfferingsModuleAspectRatio =
  | 0.25 // 4:1
  | 0.375 // 8:3
  | 0.5625 // 16:9
  | 0.6667 // 3:2
  | 0.75 // 4:3
  | 1 // 1
  | 1.3333 // 3:4
  | 1.5 // 2:3
  | 1.7778 // 9:16
  | 2.6667; // 3:8

const highlightsAspectRatios = <const>[
  1.5, // 3:2
  1.3333, // 4:3
  0.8333, // 5:6
];

export type HighlightsAspectRatio = (typeof highlightsAspectRatios)[number];

const highlightsLayout5AspectRatios = <const>[
  0.8696, 1.4286, 1.1364, 1.8182, 2.2222, 1.5,
];

export type HighlightsLayout5AspectRatio =
  (typeof highlightsLayout5AspectRatios)[number];

export type LinkData = Nullable<{
  url: string;
  pageId: string;
  languageId: Language;
  moduleId: string;
  moduleType: ModuleType;
}>;

export interface AllowedLinkTypes {
  pages: boolean;
  folders: boolean;
  modules: boolean;
  external: boolean;
}

export interface SiteFilesByPath {
  [pagePath: string]: string;
}

export interface AllSiteFilesByPath {
  html: SiteFilesByPath;
  rest: SiteFilesByPath;
}

export interface HighlightsItem {
  id: string;
  title: string;
  textAlign: TextAligns;
  subtitle: string;
  text: ReactNode;
  link: AnchorAttrs | undefined;
  buttons: ReactNode[];
  picture: Picture;
  imageDetail: ImageDetail;
}

export interface BookingWidgetSettings {
  propertyId: number;
  colorScheme: ColorScheme;
  lang: Language;
  layout: BookingWidgetLayout;
  defaultOfferList: DefaultOfferList;
  license: BookingWidgetLicense;
  fieldSettings: BookingWidgetFieldSettings;
}

export interface PortalWidgetSettings {
  id: string;
  colorScheme: ColorScheme;
  lang: Language;
  showSearch?: boolean;
}

export interface EnquiryWidgetSettings {
  propertyId: number;
  lang: Language;
  layout: EnquiryWidgetLayout;
  colorScheme: ColorScheme;
  fieldSettings: EnquiryWidgetFieldSettings;
}

export type QuickEnquiryWidgetLayout = "bottom_left" | "bottom_right";

export type QuickEnquiryBadgeStyle = "text" | "icon_square" | "icon_circle";

export interface QuickEnquiryWidgetSettings {
  propertyId: number;
  lang: Language;
  layout: QuickEnquiryWidgetLayout;
  colorScheme: ColorScheme;
  fieldSettings: QuickEnquiryWidgetFieldSettings;
  badgeStyle: QuickEnquiryBadgeStyle;
  onLoad: (self: { showForm: (show: boolean) => void }) => void;
}

export interface QuickEnquiryWidgetFieldSettings {
  stay: FieldSetting;
  guests: FieldSetting;
  gender: FieldSetting;
}

export interface BookingWidgetFieldSettings {
  gender: FieldSetting;
  phone: FieldSetting;
  street: FieldSetting;
  zipcode: FieldSetting;
  city: FieldSetting;
  country: FieldSetting;
  note: FieldSetting;
}

export interface EnquiryWidgetFieldSettings extends BookingWidgetFieldSettings {
  stay: FieldSetting;
  occupancies: FieldSetting;
  newsletter: BasicFieldSetting;
}

export type OfferingsType = "Rooms" | "Specials";

export type OfferingsLayout =
  | "layout_1"
  | "layout_2"
  | "layout_3"
  | "layout_4"
  | "layout_5"
  | "layout_6";

export type OfferingsDisplayType = "tiles" | "slides";

interface OfferingsSelf {
  openDetails: (offeringId: number) => void;
  closeDetails: () => void;
}

export interface APIOffering {
  // Contains more properties in reality, see widgets.next repository
  id: number;
  title: string;
}

export type SpecialThemeId = keyof typeof specialThemes;

export interface OfferingsWidgetSettings {
  propertyId: number;
  lang: Language;
  layout: OfferingsLayout;
  maxColumns: number;
  imageAspectRatio: OfferingsModuleAspectRatio;
  displayType: OfferingsDisplayType;
  colorScheme: ColorScheme;
  ids?: number[];
  themeIds?: SpecialThemeId[];
  onLoad?: (
    self: OfferingsSelf,
    offerings: APIOffering[]
  ) => APIOffering[] | void;
  onDetailsOpen?: (offering: APIOffering) => void;
  onDetailsClose?: (offering: APIOffering) => void;
  bookingSettings?: {
    fieldSettings?: BookingWidgetFieldSettings;
    layout?: BookingWidgetLayout;
  };
  enquirySettings?: {
    fieldSettings?: EnquiryWidgetFieldSettings;
    layout?: EnquiryWidgetLayout;
  };
}

export interface PricesWidgetSettings {
  propertyId: number;
  lang: Language;
  roomIds?: number[];
  offerIds?: number[];
  boardIds?: Board[];
  layout: PricesWidgetLayout;
  grouping: PricesWidgetGrouping;
  colorScheme: ColorScheme;
}

export type PricesWidgetLayout = "layout_1" | "layout_2" | "layout_3";

export type PricesWidgetGrouping = "offer" | "board";

export type APIWeatherIconStyle = (typeof weatherIconStyles)[number];

export type APIWeatherDistrictId =
  | (typeof weatherDistrictIds)[number]
  | undefined;

export interface WeatherWidgetSettings {
  propertyId: number;
  lang: Language;
  districtId: APIWeatherDistrictId;
  iconStyle: APIWeatherIconStyle;
  colorScheme: ColorScheme;
}

interface ResolveLinkBaseParams {
  languageId: Language;
  isPreview: boolean;
  title?: string;
  fallbackLanguageId: Language | undefined;
}

export interface GetPagePathParams {
  pageId: string;
  languageId: Language;
  pages: PageState;
  fallbackLanguageId: Language | undefined;
}

export interface GetPageUrlParams {
  pages: PageState;
  pageId: string;
  languageId: Language;
  fallbackLanguageId?: Language | undefined;
  isPreview: boolean;
}

export interface ModuleLinkParams extends PageLinkParams {
  moduleId: string;
}

export interface PageLinkParams extends ResolveLinkBaseParams {
  pageId: string;
  pages: PageState;
}

export interface ExternalLinkParams extends ResolveLinkBaseParams {
  url: string;
}

export type LinkParams = Nullable<
  Partial<ModuleLinkParams & PageLinkParams & ExternalLinkParams>
> &
  ResolveLinkBaseParams;

export type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;

export type LegalNavId = "imprint" | "privacy" | "terms";

export interface GenerateXMLSitemapsParams {
  site: APISite;
  domain: string;
  pages: PageState;
  languages: SiteLanguage[];
}

export interface GetMarkupParams {
  languageId: Language;
  canonicalLanguage: Language;
  store: Store<GeneratorStoreState>;
  title: string;
  documentTitle: string | null;
  description: string | null;
  pageId: string;
  canonicalLink: string;
  children: ReactNode;
}

export interface RenderPageMarkupParams {
  store: Store<GeneratorStoreState>;
  page: TranslatedPage;
  canonicalLink: string;
  canonicalLanguage: Language;
}

export interface EditorControl {
  icon: IconName;
  style?: string;
  title: string;
}

export type OnModuleAdd = (params: {
  moduleType: ModuleType;
  moduleId: string;
  usePageId: boolean;
  index?: number;
}) => void;

export interface PostModuleParams<
  Settings extends ModuleSettingsTypes = ModuleSettingsTypes
> {
  siteId: string;
  pageId: string | null;
  moduleId: string;
  moduleType: ModuleType;
  parentId: string | null;
  languageIds: Language[];
  next: string | undefined;
  settings?: PartialModuleSettings<Settings>;
}

export interface CreateModulePostData {
  translations: ModuleTranslations;
  next: string | null;
  moduleType: ModuleType;
  parentId: string | null;
  pageId: string | null;
}

export interface PostMoveModuleParams {
  moduleId: string;
  pageId: string | null;
  siteId: string;
  hasMovedBy: number;
}

export type LogoCategory = 0 | 1;

export interface MoveModulePostData {
  next: string | null;
  parentId: string | null;
}

export interface ColorSchemePutData {
  scheme: ColorScheme;
}

export interface DragPageParams {
  sourcePageId: string;
  targetPageId: string;
  insertBefore: boolean;
  isNewSubtree: boolean;
}

export interface DragPageActionParams extends DragPageParams {
  siteId: string;
}

export interface Revision {
  id: string;
  isFavorite: boolean;
  publishedAt: string;
}

export interface StoreRevisions {
  allIds: string[];
  byId: {
    [id: string]: Revision;
  };
}

export interface Seo {
  crawler: SeoCrawler;
  urls: SeoUrls;
}

export type CheckStatus = TaskStatus | "uninitialized";

export interface SeoCrawler {
  status: CheckStatus;
  crawledAt: string | null;
  errors: number;
}

export interface SeoUrls {
  byId: SeoUrlsById;
  allItems: string[];
  areLoading: boolean;
  isImporting: boolean;
}

export interface SeoUrlsById {
  [id: string]: SeoUrl;
}

export type GetSeoUrlsQueryParams = {
  archived?: boolean;
  redirected?: boolean;
};

export interface GetIssuesQueryParams {
  archived?: boolean;
  severity?: IssueSeverity;
  resolved?: boolean;
}

export type PageTranslationId = [pageId: string, languageId: Language] | null;

export interface SeoUrlRequest {
  pageTranslationId?: PageTranslationId;
  isArchived?: boolean;
}

export interface SeoUrl {
  pageTranslationId: PageTranslationId;
  url: string;
  firstDetected: string;
  lastCrawled: string;
  siteId: string;
  isArchived: boolean;
}

export type SeoUrlType = "errors" | "redirects" | "archive" | "import";

export interface HrefLangAttributes {
  hrefLang: Language;
  rel: "alternate";
  href: string;
}

type LanguageNames = { [L in Language]: string };

export interface I18n {
  boards: { [T in Board]: string };
  more: string;
  moreMenu: string;
  languageNames: LanguageNames;
  menu: string;
  email: string;
  phone: string;
  imprint: {
    author: string;
    icons: string;
    pictures: string;
  };
  errorOccurred: string;
  countries: {
    main: string;
    all: string;
    error: string;
  };
  genders: {
    [key in Gender]: string;
  };
  register: string;
  formFields: {
    gender: string;
    firstname: string;
    lastname: string;
    email: string;
    country: string;
  };
  newsletterMessages: {
    success: string;
    error: string;
    privacy: (privacyLink: (children: string) => ReactNode) => ReactNode;
  };
  on: string;
}

export interface TextAlignment {
  value: TextAlign;
  title: string;
  iconGlyphs: IconName;
}

export interface VerticalAlignment {
  value: VerticalAlign;
  title: string;
  iconGlyphs: IconName;
}

export interface PartnerLogoSelectionForm {
  selectedLogoId: string | undefined;
  partnerLinks: PartnerLinks;
}

export type ImageModuleIdsByPictureId = { [pictureId in string]?: string };

export interface ModuleSection {
  isSortable: boolean;
  items: Module[];
}

export interface SelectOption<OptionValues> {
  value: OptionValues;
  label: string;
}

export interface SelectOption<OptionValues> {
  value: OptionValues;
  label: string;
}

export interface DisabledModuleColors {
  main: {
    background: ModuleType[];
    text: ModuleType[];
    title: ModuleType[];
    separator: ModuleType[];
  };
  primary: {
    background: ModuleType[];
    text: ModuleType[];
  };
  secondary: {
    background: ModuleType[];
    text: ModuleType[];
  };
}

export type IssueType =
  | "orphanedLink"
  | "missingTranslation"
  | "emptyPage"
  | "missingRoomPictures"
  | "untouchedPrivacy"
  | "specialsExpired"
  | "missingLogo"
  | "missingFavicon"
  | "deletedImages"
  | "missingAltAttributes"
  | "missingImageCopyright"
  | "tooFewImageGalleryPictures"
  | "missingGoogleTagManager"
  | "missingPageDescription"
  | "missingSiteOwnerData"
  | "incompleteImprintData"
  | "mainPageDisabled"
  | "unreachableExternalLink"
  | "invalidYoutubeUrl"
  | "emptyModule";

export type IssueSeverity = "critical" | "error" | "warning";

export interface IssueItemType {
  id: string;
  type: IssueType;
  description: string;
  firstSeen: string;
  title: string | null;
  link: LinkData | null;
  isArchived: boolean;
  severity: IssueSeverity;
  resolvedAt: string | null;
}

export interface LinkCaption {
  title: string;
  icon: IconName;
}

export interface NavLinkWithIcon {
  type: ActionLinkType | "phone" | "email" | "whatsapp";
  href: string;
  icon: IconName;
  children: string;
  title: string | undefined;
}

export interface LanguageMenuItem {
  href: string;
  hrefLang: Language;
  children: string;
  rel: "alternate";
}

export type AllModulesByType = {
  [moduleType in ModuleType]: ComponentType<any>;
};

export interface RichEditorProps {
  module: TranslatedModule<ModuleWithRichTextSettingsDraftOnly>;
  pageId: string;
  languageId: Language;
  isPreview: boolean;
  maxCharacters?: number;
}

export interface LazyImageProps {
  className?: string;
  alt?: string;
  src: string;
  srcSet?: string;
  sizes?: string;
  width?: number;
  height?: number;
  loading?: "lazy" | "eager";
}

export interface PictureGroup {
  id: number;
  name: string;
}

export interface RadioOption<RadioValue> {
  value: RadioValue;
  title: string;
  iconGlyphs: IconName | IconName[];
  label?: string;
}

export interface APICountry {
  id: string;
  alpha2Code: string;
  title: string;
  order: number | null;
}

export type Gender = "m" | "f";

export interface PostNewsletterRequest {
  gender: Gender | null;
  firstName: string | null;
  lastName: string | null;
  email: string;
  languageId: Language;
  country: string | null;
}

export type FormSubmitState = "initial" | "submitting" | "error" | "submitted";

export type PictureQueryParams = Partial<{
  w: number | undefined;
  h: number | undefined;
  m: number | undefined;
  cropW: number | undefined;
  cropH: number | undefined;
  cropX: number | undefined;
  cropY: number | undefined;
}>;

export type PageSettingsOnSave = (params: {
  translation: PageTranslationPostRequest;
  popUpModuleId: string | null;
  isEnabled?: boolean;
}) => void;

export type ModuleWhatToRemove = "translation" | "allTranslations" | "nothing";

export const checkIsHttpsUrl = (input: string) =>
  isURL(input, {
    protocols: ["https"],
  });

export type PagesLimit = 1 | 10 | 20 | 200;

export interface CommonHeaderLayoutProps {
  accommodation: Accommodation;
  actionLinks: ActionLinks;
  activeModuleId: string | undefined;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  imageOverlayGradient: HeaderImageOverlayGradient;
  imagesModule: TranslatedModule<ImagesModuleSettings> | undefined;
  isPreview: boolean;
  languageId: Language;
  logo: Picture;
  logoSize: ThreeSizes;
  logoBackground: HeaderLogoBackground;
  mainPageURL: string | undefined;
  menuItems: MainMenuItem[];
  pageId: string;
  pages: PageState;
  queries: ContainerQueries;
  scheme: ColorScheme;
  showSouthTyrolLogo: boolean;
  sticky: boolean;
  whatsAppNumber: string | undefined;
}

export interface QuickLink {
  href: string;
  label: string;
  type: ActionLinkType;
}

export type PatchSiteBase = Partial<
  Pick<
    APISite,
    | "vatId"
    | "legalRepresentative"
    | "colorSchemeId"
    | "fontFamily"
    | "titleFontFamily"
    | "titleFontForSubtitle"
    | "fontSize"
    | "titleFontSize"
    | "googleTagManagerId"
    | "googleMapsApiKey"
    | "languages"
    | "popUpModuleId"
  >
>;

export type PatchSiteClient = PatchSiteBase & Partial<Pick<APISite, "favicon">>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "odh-howtoarriveinsouthtyrol-widget": {
        "data-color": string;
        "data-lang": string;
      };
      "bn-getavo": {
        id: string;
        class: string;
        "data-lang": Language;
        "data-code": string;
      };
    }
  }
}

export enum DragDropType {
  NewModule = "newModule",
  Module = "module",
  ModuleDropSpace = "moduleDropSpace",
  EmptyModules = "emptyModules",
}

export interface DragDropTypes {
  [DragDropType.NewModule]: {
    type: DragDropType.NewModule;
    moduleType: ModuleType;
  };
  [DragDropType.Module]: { type: DragDropType.Module; id: string };
  [DragDropType.ModuleDropSpace]: {
    type: DragDropType.ModuleDropSpace;
    index: number;
  };
  [DragDropType.EmptyModules]: { type: DragDropType.EmptyModules };
}

export enum ModuleSortInsertPosition {
  Before = -1,
  After = 1,
}
